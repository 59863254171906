import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/pages/Home"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Do It Best Industrial Supply"
      description="Do It Best Industrial Supply is a hardware store in Calvert, AL that sells home hardware, tools, garden supplies, lumbar, and supplies the industrial markets. We offer lumbar and pipe/chain cutting, key cutting, and carry a variety of paint supplies."
    />
    <Home />
  </Layout>
)

export default IndexPage
